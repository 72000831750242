module.exports = {
    methods: {
        getExpandFields() {
            return Object.keys(this.fields).reduce((expandFields, fieldName) => {
                var field = this.fields[fieldName];
                if (field.TypeAsString === 'Lookup' || field.TypeAsString === 'LookupMulti') {
                    expandFields = expandFields.concat([`${fieldName}/Id`, `${fieldName}/${field.LookupField}`]);
                } else if (field.TypeAsString === 'User') {
                    expandFields = expandFields.concat([`${fieldName}/Id`, `${fieldName}/Title`, ` ${fieldName}/UserName`]);
                }

                return expandFields;
            }, []).join(',');
        },
        loadFieldValues(item) {
            Object.keys(this.fields).map(fieldName => {
                var field = this.fields[fieldName];
                var value = item[fieldName];
                if (value == null) {
                    value = null
                } else {  
                    if (field.TypeAsString == 'DateTime') {
                        value = moment(value).toDate()
                    } else if (field.TypeAsString == 'MultiChoice') {
                        value = value.results
                    } else if (field.TypeAsString == 'Lookup') {
                        value = value.Id ? value : null;
                    } else if (field.TypeAsString == 'LookupMulti') {
                        value = value.results.map(item => item.Id)
                    } else if (field.TypeAsString == 'User') {
                        if (value.Id) {
                            value.PreferredName = value.Title;
                            value.AccountName = value.UserName;
                            value = value;
                        } else {
                            value = null;
                        }
                    } else if(field.TypeAsString == 'Boolean') {
                        if(value == true){
                            value = 'true'
                        }else if(value == false) {
                            value = 'false';
                        }else {
                            value = null;
                        }
                    }
                    Vue.set(field, 'Value', value);
                }
            });
            return false;
        },
        getDataToSave() {
            debugger
            return Object.keys(this.fields).reduce((data, fieldName) => {
                var field = this.fields[fieldName];
                let value = field.Value;

                if (field.TypeAsString == 'Lookup' || field.TypeAsString == 'UserMulti' || field.TypeAsString == 'User') {
                    fieldName += 'Id';
                    value = (value || {}).Id || null;

                    if (field.AllowMultipleValues) {
                        value = { results: (value || []).map(item => item.Id) };
                    }
                } else if (field.TypeAsString === 'LookupMulti') {
                    fieldName += 'Id';
                    value = { results: value || [] };
                } else if (field.TypeAsString === 'MultiChoice') {
                    value = { results: value || [] };
                } else if (field.TypeAsString === 'DateTime') {
                    value = moment(value);
                    value = value.isValid() ? value.toDate() : null
                } else if(field.TypeAsString === 'Boolean') {
                    if(value == true){
                        value = 'true'
                    }else if(value == false) {
                        value = 'false';
                    }else {
                        value = null;
                    }
                }

                data[fieldName] = value;
                return data;
            }, {});
        }
    }
}