require('../components/redes-sociais');
require('../mixins/translate');
require('./form-newsletter');
require('./cookies');

$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

function menuGlobalScope(Vue, $pnp, pnpMixin) {
    var menuMixin = {
        data: function () {
            return {
                isMobile: false
            }
        },
        created: function () {
            this.onResize();
            $(window).resize(this.debounce(this.onResize.bind(this), 500))
        },
        methods: {
            onResize: function () {
                this.isMobile = window.innerWidth < 1024
            },
            debounce: function (func, wait, immediate) {
                var timeout;
                return function () {
                    var context = this, args = arguments;
                    var later = function () {
                        timeout = null;
                        if (!immediate) func.apply(context, args);
                    };
                    var callNow = immediate && !timeout;
                    clearTimeout(timeout);
                    timeout = setTimeout(later, wait);
                    if (callNow) func.apply(context, args);
                };
            }
        }
    };

    // component de repetição
    Vue.component('menu-footer-multinivel', {
        mixins: [menuMixin, translateMixin],
        props: {
            items: {
                type: Array,
                default: function () {
                    return []
                }
            },
            customClass: {
                type: String,
                default: ''
            },
            isChild: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            hasChildren: function (item) {
                return (item.children || []).length > 0;
            },
            isActive(item) {
                var childrenIsActive = false;

                var checkChildren = item.children.filter((children) => {
                    if (_spPageContextInfo.serverRequestPath.indexOf(children.URL) > -1) {
                        childrenIsActive = true;
                    }
                })

                if (checkChildren.length)
                    childrenIsActive = true;

                if ((_spPageContextInfo.serverRequestPath.indexOf(item.URL) > -1 || (item.children.length && childrenIsActive)) && item.Title != 'Home') {
                    return true
                } else if (item.Title == 'Home' && _spPageContextInfo.serverRequestPath.toLowerCase().indexOf('default.aspx') > -1) {
                    return true
                }
            },
        },
        template:
            `
		<ul class="navbar-nav mr-auto menu-footer">
			<template v-for="(item, index) in items">
				<li class="nav-item"
				>
					<a :href="item.URL" class="nav-link text-uppercase"
						:id="'item'+ (item.ItemPrincipalId || item.Id)" 
						:target="item.NovaAba == 'Sim' ? '_blank' : '_self'"
					>
						{{ !isEnglish ? item.Title : item.TitleEN }}
					</a>
					<menu-footer-multinivel v-if="hasChildren(item)" style="display: block" :items="item.children" :is-child="true" :id="'item'+ item.Id"/>
				</li>
			</template>
		</ul>`,
        created() { },
    })

    Vue.component('footer-component', {
        mixins: [translateMixin, pnpMixin, menuMixin],
        data: function () {
            return {
                items: [],
            }
        },
        created: function () {
            this.getItems(_spPageContextInfo.siteServerRelativeUrl, _spPageContextInfo.siteAbsoluteUrl)
                .then(this.parseLevels)
                .then(this.setItems)
        },
        methods: {
            getItems: function (webRelative, webAbsolute) {
                var web = new $pnp.Web(webAbsolute);

                return web.getList(webRelative + '/lists/MenuGlobal').items
                    .top(500)
                    .orderBy('OrdemRodape')
                    .filter(`TipoMenu eq 'Rodape'`)
                    .get()
            },
            setItems: function (items) {
                this.items = items;
            },
            getChildren: function (items, parentId) {
                return items.filter(function (item) {
                    return parentId === item.ItemPrincipalId;
                }).map(function (item) {
                    item.levelId = item.ItemPrincipalId;
                    return item;
                })
            },
            clone: function (obj) {
                if (null == obj || "object" != typeof obj) return obj;
                var copy = obj.constructor();
                for (var attr in obj) {
                    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
                }
                return copy;
            },
            cloneArray: function (array) {
                return JSON.parse(JSON.stringify(array));
            },
            parseLevels: function (items) {
                var allItems = this.cloneArray(items);
                var reduceLevels = function (levelAtual, curItem, index) {
                    // é necessário clonar pois propriedades como levelId e children são alteradas nos loops
                    // quando não clonamos, corremos o risco de perder a referencia de levelId por exemplo

                    if (curItem.levelId == curItem.ItemPrincipalId || curItem.ID == curItem.levelId) {
                        curItem.levelId = curItem.ID;
                        curItem.children = this.getChildren(allItems, curItem.ID).reduce(reduceLevels.bind(this), []);
                        levelAtual.push(curItem)
                    }
                    return levelAtual;
                }

                items = items.reduce(reduceLevels.bind(this), []);
                return items;
            },
        },
        template: `
        <element>
            <div class="row">
                <div class="column col-sm-3">
                    <h3 class="text-primary">{{ isEnglish ? 'Menu' : 'Navegação' }}</h3>
                    <menu-footer-multinivel :items="items" :is-mobile="isMobile"/>
                </div>
                <div class="column col-sm-4">
                    <h3 class="text-primary">{{ isEnglish ? 'Social Media' : 'Redes sociais' }}</h3>
                    <redes-sociais />
                </div>
                <div class="column col-sm-4 newsletter">
                    <form-newsletter/>
                </div>
            </div>
            <div class="copyright mt-4 row">
                <div class="col-sm-6">
                    <ul class="nav">
                        <li class="nav-item"><a href="" class="pl-0 nav-link">&copy; <span class="current-year"></span> - {{ isEnglish ? 'All rights reserved' : 'Todos os direitos reservados' }}.</a></li>
                        <li class="nav-item"><a href="/termos-de-uso" class="nav-link">{{ isEnglish ? 'Terms of use' : 'Termos de uso' }}</a><li>
                        <li class="nav-item"><a href="/politica-de-privacidade" class="nav-link">{{ isEnglish ? 'Privacy policy' : 'Política de privacidade' }}</a></li>
                    </ul>
                </div>
                <div class="col-sm-6 text-right">
                    <p>Developed by <a href="https://simbiox.com.br" title="SimbioX" target="_blank">SimbioX</a></p>
                </div>
            </div>
            <cookies/>
        </element>
        `
    });

    new Vue({
        el: '#footer-component',
        template: '<footer-component/>'
    })
}
menuGlobalScope(Vue, $pnp, pnpMixin);
