$pnp.setup({
    sp: {
        headers: {
            "Accept": "application/json; odata=verbose"
        }
    }
});
require('../mixins/translate');
require('../mixins/pnp-mixin');
// component importado pelo menu-global
Vue.component('menu-areas-atuacao', {
    mixins: [translateMixin, pnpMixin],
    data: function () {
        var listName = 'AreasAtuacao'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.webServerRelativeUrl + '/lists/' + listName + '',
            areasAtuacao: [],
            itemsPerColumn: 11
        }
    },
    template: `
        <div id="mega-menu-areas">
            <div class="container bg-secondary row align-items-start">
                <div v-for="(par,index) in areasAtuacao" class="col-sm-4">
                    <ul class="list-items">
                        <li class="Link" v-for="item in par">
                            <a :href="getDispArea(item.SeoURL)" :title="isEnglish ? item.TitleEN : item.Title">
                                {{ isEnglish ? item.TitleEN : item.Title }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `,
    methods: {
        getAreasAtuacao() {
            return $pnp.sp.web.getList(this.listUrl).items
                .filter(`ExibirMenu eq 1`)
                .select('*')
                .orderBy('Title', true)
                .top(100)
                .get()
        },
        getPair(items, groupBy) {
            groupBy = groupBy || 3;
            return items.reduce(function (pairs, item, index) {
                var multiplo = Math.ceil((index + 1) / groupBy)
                var currentPair = (pairs[multiplo] || []).concat(item)
                pairs[multiplo] = currentPair
                return pairs
            }, {});
        },
        getDispArea(url) {
            //return `${_spPageContextInfo.webServerRelativeUrl}pages/detalhes-atuacao.aspx?area=${url}`
            return `${_spPageContextInfo.siteAbsoluteUrl}/areas-atuacao/${url}`
        }
    },
    created() {
        this.getAreasAtuacao().then((results) => {
            this.areasAtuacao = this.getPair(results, this.itemsPerColumn)
        }).catch((err) => {
            this.threatError(err, 'Erro ao carregar menu de áreas.')
        })
    },
});