// importando components utilizados no form-field;
const vSelect = require('vue-select').VueSelect;

Vue.component('form-field', {
    components: { vSelect },
    template: `
    <element v-if="field">
        <label class="font-weight-bold d-block m-0" v-if="!field.HideLabel">
            {{ field.Title }} <span v-if="field.Required" class="text-danger">*</span>
        </label>
        <template v-if="field.TypeAsString === 'Lookup' || field.TypeAsString === ' '">
            <v-select v-if="!field.ReadOnlyField"
				:debounce="250"
				:options="field.Choices"
                :multiple="field.AllowMultipleValues"
                :value.sync="field.Value"
                :label="field.LookupField"
                :on-change="onChangeLookup"
                :disabled="disabled"
                :tabindex="tabIndex"
                :placeholder="field.Required ? field.Placeholder + '*' : field.Placeholder"
            >
                <span slot="no-options">
                    Nenhum resultado encontrado
                </span>
            </v-select>
            <span v-else>
                {{(field.Value || {})[field.LookupField]}}
            </span>
        </template>
        <template v-else-if="field.TypeAsString === 'LookupMulti' && field.AllowMultipleValues === true">
            <template v-if="!field.ReadOnlyField">
                <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="todos" @click="selectAll(field)" :checked="field.Choices.length == field.Value.length"  :disabled="field.ReadOnlyField" :tabindex="tabIndex"/>
                    <label class="form-check-label" for="todos">Todos</label>
                </div>
                <div class="form-check form-check-inline" v-for="choice in field.Choices">
                    <input class="form-check-input" 
                            type="checkbox" 
                            :id="choice.Id" 
                            :value="choice.Id" 
                            v-model="field.Value" 
                            :disabled="field.ReadOnlyField"     
                            :tabindex="tabIndex"                       
                    />
                    <label class="form-check-label" :for="choice.Id">{{choice.Title}}</label>
                </div>
            </template>
            <template v-else>
                <ul class="list-inline">
                    <li class="list-inline-item">
                        {{ viewMultiFields() }}
                    </li>
                </ul>
            </template>
        </template>
        <template v-else-if="field.TypeAsString === 'User'">
            <people-picker v-if="!field.ReadOnlyField" 
                :model="field" @change="onChange" 
                :group-name="field.GroupName" 
                :group-id="field.GroupId"
            />
            <span v-else>{{ (field.Value || {}).PreferredName || 'Sem informações.' }}</span>
        </template>
        <template v-else-if="field.TypeAsString === 'DateTime'">
            <v-date-picker
                v-if="!field.ReadOnlyField"
                popover-visibility="focus"
                mode="single"
                v-model="field.Value"
                class="d-block"
                :min-date="minDate"
                :input-props="{ placeholder: field.Title, class: 'form-control' }"
                :tabindex="tabIndex"
            >
            </v-date-picker>
            <span v-else>
                <template v-if="field.DisplayFormat === 1">
                    {{ field.Value ? moment(field.Value).add(1, 'hour').format('DD/MM/YYYY - HH:mm') : 'Sem data selecionada' }}
                </template>
                <template v-else>
                    {{ field.Value ? moment(field.Value).format('DD/MM/YYYY') : 'Sem data selecionada' }}
                </template>
            </span>
        </template>
        <template v-else-if="field.TypeAsString === 'Choice'">
            <template v-if="field.EditFormat === 0">  
                <template v-if="!field.ReadOnlyField">  
                    <select class="custom-select" v-model="field.Value" @change="onChange" :disabled="field.ReadOnlyField" :tabindex="tabIndex">
                        <option :value="null">{{ field.ReadOnlyField ? 'Nenhum' : (field.Placeholder || 'Selecione') }}</option>
                        <option :value="choice" v-for="choice in field.Choices.results">
                            {{ choice }}
                        </option>
                    </select>
                </template>
                <template v-else>
                    <span>{{ field.Value }}</span>
                </template>
            </template>
            <template v-if="field.EditFormat === 1">    
                <template v-if="!field.ReadOnlyField">
                    <div class="form-check" v-for="choice in field.Choices.results">
                        <input class="form-check-input" 
                            v-model="field.Value" @change="onChange" 
                            type="radio"
                            :value="choice" 
                            :id="choice+field.InternalName"
                            :disabled="field.ReadOnlyField"
                            :tabindex="tabIndex"
                        >
                        <label class="form-check-label" :for="choice+field.InternalName">
                            {{ choice }}
                        </label>
                    </div>
                </template>
                <template v-else>
                    <span>{{ field.Value }}</span>
                </template>
            </template>
        </template>
        <template v-else-if="field.TypeAsString === 'MultiChoice'">
            <template v-if="!field.ReadOnlyField">
                <div class="form-check" v-for="choice in field.Choices.results">
                    <input class="form-check-input" 
                        v-model="field.Value" @change="onChange" 
                        type="checkbox"
                        :value="choice" 
                        :id="choice+field.InternalName"
                        :disabled="field.ReadOnlyField"
                        :tabindex="tabIndex"
                    >
                    <label class="form-check-label" :for="choice+field.InternalName">
                        {{ choice }}
                    </label>
                </div>
            </template>
            <template v-else>
                <span>{{ field.Value }}</span>
            </template>
        </template>
        <template v-else-if="field.TypeAsString === 'Boolean'">
            <template v-if="!field.ReadOnlyField">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" 
                        v-model="field.Value" @change="onChange" 
                        type="radio"
                        :value="true" 
                        :id="'sim'+field.InternalName"
                        :disabled="field.ReadOnlyField"
                        :tabindex="tabIndex"
                    >
                    <label class="form-check-label" :for="'sim'+field.InternalName">
                        Sim
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input"
                        v-model="field.Value" @change="onChange"
                        type="radio"
                        :value="false"
                        :id="'nao'+field.InternalName"
                        :disabled="field.ReadOnlyField"
                        :tabindex="tabIndex"
                    >
                    <label class="form-check-label" :for="'nao'+field.InternalName">
                        Não
                    </label>
                </div>
            </template>
            <template v-else>
                <span>{{ field.Value == 'false' ? 'Não' : 'Sim' }}</span>
            </template>
        </template>
        <template v-else-if="field.TypeAsString === 'Note'">
            <template v-if="!field.ReadOnlyField">
                <textarea :rows="field.NumberOfLines" type="text" class="form-control" v-model="field.Value" @change="onChange" :placeholder="field.Required ? field.Placeholder + '*' : field.Placeholder" :tabindex="tabIndex"/>
            </template>
            <template v-else>
                <span>{{ field.Value ? field.Value : 'Sem informações.' }}</span>
            </template>
        </template>
        <template v-else>
            <template v-if="!field.ReadOnlyField">
                <input 
                    type="text"
                    class="form-control"
                    v-model="field.Value" @change="onChange"
                    v-mask="mask || null"
                    :placeholder="field.Required ? field.Placeholder + '*' : field.Placeholder"
                    :class="{'border-danger border-bottom' : field.Invalid}"
                    :tabindex="tabIndex"
                />
            </template>
            <template v-else>
                {{ field.Value }}
            </template>
        </template>
    </element>`,
    props: {
        field: { required: true },
        minDate: { default: null },
        disabled: { default: false },
        mask: {default: null},
        tabIndex: '',
        mapChoices: { default: false }
    },
    created() {
        /*Vue._installedPlugins.find(function(plugin) {
            if (plugin.hasOwnProperty('Calendar')) {
                plugin.install(Vue, { locale: 'pt-BR' })
            }
        });*/

        if (this.field.TypeAsString === 'Lookup' || this.field.TypeAsString === 'LookupMulti') {
            this.$set(this.field, 'Choices', []);
            if (this.field.TypeAsString === 'LookupMulti') {
                this.$set(this.field, 'Value', this.field.Value || []);
            } else {
                this.$set(this.field, 'Value', this.field.Value || null);
            }
            this.loadLookupChoices();
        }

        this.isLoaded = true;
    },
    methods: {
        loadLookupChoices() {
            let select = `Id, ${this.field.LookupField}`;
            select = this.field.LookupSelect ? `${select},${this.field.LookupSelect}` : select;

            let call = $pnp.sp.web.lists.getById(this.field.LookupList).items
                .select(select)
                .top(1000)
                .orderBy(this.field.LookupOrder || this.field.LookupField)

            call = this.field.LookupExpand ? call.expand(this.field.LookupExpand) : call;

            call = call.get();

            if (this.mapChoices) {
                call = call.then(result => result.map(this.mapChoices))
            }

            if (this.field.LookupFilter) {
                let filter = this.field.LookupFilter;

                if (this.isFunction(filter)) {
                    filter = this.field.LookupFilter.apply(this);
                }
                call = call.filter(filter);
            }

            return call
                .then(choices => this.$set(this.field, 'Choices', choices));

        },
        selectAll(field) {
            var hasTodos = field.Choices.length == field.Value.length;

            if (hasTodos) {
                Vue.set(field, 'Value', [])
            } else {
                Vue.set(field, 'Value', field.Choices.map(choice => choice.Id))
            }
        },
        isFunction(functionToCheck) {
            return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
        },
        onChange() {
            this.$emit('change', this.field.Value);
        },
        onChangeLookup(val) {
            Vue.set(this.field, 'Value', val);
            this.onChange();
        },
        viewMultiFields() {
            return this.field.Choices.filter(item => {
                return this.field.Value.find(function(value) {
                    return value == item.Id
                }.bind(this));
            }).map(function(item) { return item[this.field.LookupField] }.bind(this)).join(',');
        }
    },
    data: function() {
        return {
            moment: moment,
            isLoaded: false
        }
    },
    watch: {
        'field.LookupFilter': {
            handler: function(val, oldVal) {
                this.loadLookupChoices();
            },
            deep: true
        }
    }
})