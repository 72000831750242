window.translateMixin = {
    methods: {
        changeLanguage(value) {
            if (CurrentLanguageDecimal == value) {
                return null;
            }

            var today = new Date();
            var oneYear = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
            var url = window.location.href;
            document.cookie = "lcid=" + value + ";path=/;expires=" + oneYear.toGMTString();
            location.href = url;
            location.reload()
        },
        changePageTitle(){
            return $pnp.sp.web.getList(this.libraryPagesURL).items
                .getById(this.idCurrentPage)
                .select(`Id, Title, TitleEN, Descricao, DescricaoEN, IsDisp`)
                .get().then((page) => {
                    Vue.set(this, 'infosCurrentPage', page);
                    if(!page.IsDisp){
                        
                        document.title = this.isEnglish ? 'Loading...': 'Carregando...';
                        if(this.isEnglish) {
                            if(page.Title == null){
                                document.title = this.currentTitleBrowser;
                            }else{
                                document.title = `${page.TitleEN} | ${this.currentTitleBrowser}`;
                            }
                        }else {
                            document.title = `${page.Title} | ${this.currentTitleBrowser}`
                        }
                        this.loadedInfosPage = true
                    }
                    
                }).catch((err) => {
                    console.error(`${err - 'Erro ao carregar informações da biblioteca de páginas.'}`)
                })
        }
    },
    data() {
        return {
            isEnglish: CurrentLanguageDecimal == 1033,
            currentLang: CurrentLanguageDecimal,
            libraryPagesURL: _spPageContextInfo.serverRequestPath.toLocaleLowerCase().match(/pages/i) ? '/pages' : 'paginas',
            idCurrentPage: _spPageContextInfo.pageItemId,
            infosCurrentPage: {},
            currentTitleBrowser: _spPageContextInfo.webTitle,
            loadedInfosPage: false
        };
    },
    created() {
        if(this.idCurrentPage && !this.loadedInfosPage){
            this.changePageTitle()
        }
    },
}