var translateMixin = require('./translate');

module.exports = {
    mixins: [translateMixin],
    methods: {
        threatError(msg, data) {
            console.error(arguments);
            var defaultMsg = !this.isEnglish ? "Ocorreu um erro" : "An error has ocurred"

            return swal({
                title: "Erro",
                text: msg || defaultMsg,
                type: "error"
            });
        },
        clone(obj) {
            if (null == obj || "object" != typeof obj) return obj;
            return JSON.parse(JSON.stringify(obj));
        },
        getEmptyItem(listFields) {
            return this.clone(listFields);
        },
        validateRequiredField(fields) {
            return !Object.keys(fields).some((key) => {
                return !fields[key].Value && fields[key].Required;
            })
        },
        getSEOUrl(parametro, url) {
            // parametro - caso esteja sem SEOUrl
            // url - url da página já com SEOUrl, já que tudo depois dela é parametro de SEOUrl ('/linkdapagina/')
            return $pnp.util.getUrlParamByName(parametro) || location.href.split(`/${url}/`).reverse()[0].split('#')[0].split('?')[0];
        },
        getInvalidFields(fields) {
            let msgsErro = [];
            let msgObrigatorio = false;

            fields = Object.keys(fields).filter((key) => {
                var field = fields[key];
                if (field.InternalName === 'Email' && field.Value) {
                    let emailValido = this.validateEmail(field.Value);
                    if (!emailValido) {
                        msgsErro.push(`${!this.isEnglish ? 'O e-mail' : 'The email'} <span class="font-weight-bold">${field.Value}</span> ${!this.isEnglish ? 'é inválido' : 'is invalid'}`)
                    }
                    return !emailValido;
                } else if (field.Required) {
                    let validation = false;
                    if (field.TypeAsString === 'LookupMulti') {
                        validation = !(field.Value || []).length;
                    } else {
                        validation = !field.Value
                    }
                    if (validation && msgObrigatorio == false) {
                        msgObrigatorio = true;
                        //msgsErro.push(`O campo ${field.Title} é obrigatório`)
                        msgsErro.push(!this.isEnglish ? 'Preencha os campos obrigatórios' : 'Fill in the required fields')
                    }
                    return validation;
                }
            })
            if (msgObrigatorio >= 1) {
                //alert('Preencha os campos obrigatórios');     

            }
            return {
                msgsErro,
                fields
            }
        },
        exportVCard(ID) {
            var F = document.forms[0];
            F.action = "/_layouts/15/SimbioX.SP2013.SPExporter/ExportToVCard.ashx";
            F.__VIEWSTATE.name = 'NOVIEWSTATE';
            F.__VIEWSTATE.value = "List:Profissionais;ID:" + ID + ";ListURL:;";
            F.method = 'POST';
            F.onsubmit = function() {};
            F.submit();
        },
        exportPDF(element, fileName) {
            $(`#${element}`).show()

            /*var cache_width = $(element).width(); //Criado um cache do CSS
            var a4  =[ 595.28,  841.89]; // Widht e Height de uma folha a4

            // Setar o width da div no formato a4
            $(element).width((a4[0]*1.33333) -80).css('max-width','none');
            // Aqui ele cria a imagem e cria o pdf
            html2canvas(document.querySelector(element)).then(canvas => {
                document.body.appendChild(canvas);
                var img = canvas.toDataURL("image/png",1.0);  
                var doc = new jsPDF({unit:'px', format:'a4'});
                doc.addImage(img, 'JPEG', 20, 20);
                doc.save(`Download de PDF - ${fileName}.pdf`);
                //Retorna ao CSS normal
                $(element).width(cache_width);
                $(element).hide()
            });*/

            var quotes = document.getElementById(element);
            html2canvas(quotes).then((canvas) => {
                //! MAKE YOUR PDF
                var pdf = new jsPDF('p', 'pt', 'letter');

                for (var i = 0; i <= quotes.clientHeight / 980; i++) {
                    //! This is all just html2canvas stuff
                    var srcImg = canvas;
                    var sX = 0;
                    var sY = 980 * i; // start 980 pixels down for every new page
                    var sWidth = 980;
                    var sHeight = 980;
                    var dX = 0;
                    var dY = 0;
                    var dWidth = 980;
                    var dHeight = 980;

                    window.onePageCanvas = document.createElement("canvas");
                    onePageCanvas.setAttribute('width', 980);
                    onePageCanvas.setAttribute('height', 980);
                    var ctx = onePageCanvas.getContext('2d');
                    // details on this usage of this function: 
                    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
                    ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

                    // document.body.appendChild(canvas);
                    var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

                    var width = onePageCanvas.width;
                    var height = onePageCanvas.clientHeight;

                    //! If we're on anything other than the first page,
                    // add another page
                    if (i > 0) {
                        pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
                    }
                    //! now we declare that we're working on that page
                    pdf.setPage(i + 1);
                    //! now we add content to that page!
                    pdf.addImage(canvasDataURL, 'PNG', 10, 10, (width * .62), (height * .62));

                }
                //! after the for loop is finished running, we save the pdf.
                pdf.save(`${fileName}.pdf`);
                $(`#${element}`).hide()
            });
        },
        validateEmail(email = '') {
            let usuario = email.substring(0, email.indexOf("@"));
            let dominio = email.substring(email.indexOf("@") + 1, email.length);

            if ((usuario.length >= 1) &&
                (dominio.length >= 3) &&
                (usuario.search("@") == -1) &&
                (dominio.search("@") == -1) &&
                (usuario.search(" ") == -1) &&
                (dominio.search(" ") == -1) &&
                (dominio.search(".") != -1) &&
                (dominio.indexOf(".") >= 1) &&
                (dominio.lastIndexOf(".") < dominio.length - 1)) {
                return true;
            } else {
                return false;
            }
        }
    },
    data() {
        return {
            letrasAlfabeto: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            meses: [{
                    Title: 'Janeiro',
                    TitleEN: 'January'
                },
                {
                    Title: `Fevereiro`,
                    TitleEN: `February`
                },
                {
                    Title: `Março`,
                    TitleEN: `March`
                },
                {
                    Title: `Abril`,
                    TitleEN: `April`
                },
                {
                    Title: `Maio`,
                    TitleEN: `May`
                },
                {
                    Title: `Junho`,
                    TitleEN: `June`
                },
                {
                    Title: `Julho`,
                    TitleEN: `July`
                },
                {
                    Title: `Agosto`,
                    TitleEN: `August`
                },
                {
                    Title: `Setembro`,
                    TitleEN: `September`
                },
                {
                    Title: `Outubro`,
                    TitleEN: `October`
                },
                {
                    Title: `Novembro`,
                    TitleEN: `November`
                },
                {
                    Title: `Dezembro`,
                    TitleEN: `December`
                }
            ]
        }
    },
}