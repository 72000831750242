function menuGlobalScope(Vue, $pnp, pnpMixin) {
    var menuAdmMixin = {
        methods: {
            closeOpenedItems: function(items) {
                items.forEach(function(item) {
                    if (item.isOpened) {
                        item.isOpened = false;
                        if (item.children)
                            this.closeOpenedItems(item.children);
                    }
                }.bind(this));
            }
        }
    };

    $pnp.setup({
        headers: {
            "Accept": "application/json; odata=verbose"
        }
    });

    Vue.component('menu-adm-multinivel', {
        mixins: [menuAdmMixin],
        props: {
            items: {
                type: Array,
                default: function() { return []; }
            },
            customClass: {
                type: String,
                default: ''
            },
            customId: {
                type: String,
                default: ''
            }
        },
        data: function() {
            return {
                list: {
                    newUrl: _spPageContextInfo.webAbsoluteUrl + '/lists/MenuAdm/new.aspx',
                }
            }
        },
        methods: {
            onItemMouseHover: function(item) {
                if (item.children.length) {
                    this.closeOpenedItems(this.items);
                    Vue.set(item, 'isOpened', !item.isOpened);
                }
            },
            getTooltip: function(item) {
                if (!item.ItemPrincipalId) {
                    return 'tooltip'
                }
            },
            getPlacement: function(item) {
                if (!item.ItemPrincipalId) {
                    return 'right'
                }
            },
            getToggleLink: function(item) {
                if (item.children.length) {
                    return 'collapse'
                } else {
                    return ''
                }
            },
            getClassLink: function(item) {
                if (item.children.length) {
                    return 'dropdown-toggle'
                } else {
                    return ''
                }
            },
            getClassLi: function(item) {
                if (!item.ItemPrincipalId) {
                    return 'nav-item'
                }
            },
            getLink: function(item) {
                if (!item.children.length) {
                    return _spPageContextInfo.siteAbsoluteUrl + item.URL
                } else {
                    return '#menu' + item.ID
                }
            }
        },
        template: `
            <ul :class="customClass || 'list-unstyled components'" v-if="items.length">
                <template v-for="(item, index) in items">
                    <li>
                        <a :href="getLink(item)" aria-expanded="false" :class="getClassLink(item)" :data-toggle="getToggleLink(item)">
                            <i :class="item.icon" class="text-primary" v-show="item.icon"></i>
                            <span class="nav-link-text">{{ item.Title }}</span>
                        </a>
                        <menu-adm-multinivel :items="item.children" custom-class="collapse list-unstyled" :id="'menu'+item.ID"/>
                    </li>
                </template>
            </ul>`
    })

    Vue.component('menu-adm', {
        mixins: [pnpMixin, menuAdmMixin],
        data: function() {
            var listName = 'MenuAdm'
            return {
                items: [],
                listName: listName,
                isOpened: false,
                isUserAllowed: false,
                isGroupGestor: false,
                groupUserLogado: null,
                isMobile: window.innerWidth < 996 ? true : false,
                listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`
            }
        },
        created: function() {
            this.checkGroup()
                .then(this.getItems.bind(this, _spPageContextInfo.siteServerRelativeUrl, _spPageContextInfo.siteAbsoluteUrl))
                .then(this.parseLevels)
                .then(this.setItems)
                .then(this.applyEvents)
        },
        mounted: function() {
            document.onclick = this.onClickOutside;
        },
        methods: {
            onClickOutside: function() {
                this.closeOpenedItems(this.items);
                this.openMenu(false)
            },
            getItems: function(webRelative, webAbsolute) {
                var web = new $pnp.Web(webAbsolute);
                return web.getList(this.listURL).items
                        .top(500)
                        .orderBy('Ordem')
                        .get()
            },
            setItems: function(items) {
                this.items = items;
            },
            getChildren: function(items, parentId) {
                return items.filter(function(item) {
                    return parentId === item.ItemPrincipalId;
                }).map(function(item) {
                    item.levelId = item.ItemPrincipalId;
                    return item;
                })
            },
            clone: function(obj) {
                if (null == obj || "object" != typeof obj) return obj;
                var copy = obj.constructor();
                for (var attr in obj) {
                    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
                }
                return copy;
            },
            cloneArray: function(array) {
                return JSON.parse(JSON.stringify(array));
            },
            parseLevels: function(items = []) {
                var reduceLevels = function(levelAtual, curItem, index) {
                    // é necessário clonar pois propriedades como levelId e children são alteradas nos loops
                    // quando não clonamos, corremos o risco de perder a referencia de levelId por exemplo
                    var allItems = this.cloneArray(items);

                    if (curItem.levelId == curItem.ItemPrincipalId || curItem.ID == curItem.levelId) {
                        curItem.levelId = curItem.ID;
                        curItem.children = this.getChildren(allItems, curItem.ID).reduce(reduceLevels.bind(this), []);
                        levelAtual.push(curItem)
                    }
                    return levelAtual;
                }

                items = items.reduce(reduceLevels.bind(this), []);
                return items;
            },
            onMenuClick: function() {
                this.openMenu(!this.isOpened);
            },
            openMenu: function(open) {
                this.isOpened = open;
            },
            checkGroup: function() {
                if (!_spPageContextInfo.userId) return Promise.resolve();
                var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
                return web.siteUsers.getById(_spPageContextInfo.userId).expand('groups')
                    .get()
                    .then(userGroups => {
                        this.$set(this, 'groupUserLogado', userGroups.Groups.results)
                        return userGroups.Groups.results.find(grupo => {
                            if (grupo.Title === 'Cescon Owners') {
                                this.isGroupGestor = true
                            }
                        })
                    })  
            },
            applyEvents() {
                $(document).ready(function() {
                    $('#sidebarCollapse').on('click', function() {
                        $(this).parent().parent().toggleClass('active');
                     });
                });
            }
        },
        template: `
        <element v-show="isGroupGestor">
            <button type="button" id="sidebarCollapse" class="btn btn-primary">
                <i class="fas fa-bars"></i>
            </button>
            <h3>Menu Administrativo</h3>
            <div id="scroll-adm" class="scroll-menu">
                <menu-adm-multinivel :items="items"/>
            </div>
        </element>
        `
    })
}
menuGlobalScope(Vue, $pnp, pnpMixin);

var app = new Vue({
    el: "#menu-adm",
    template: "<menu-adm/>"
})