////////////////////////////////////////////
//  MASTER - UNIFICANDO COMPONENTS E JS
////////////////////////////////////////////
require('../mixins/translate');             // translate
require('../mixins/pnp-mixin');             // pnp mixin (utilizado no menu-global);
require('./redes-sociais');                 // redes sociais
require('./menu-global');                   // menu global
require('./menu-adm');                      // menu admin
require('./menu-adm-content');              // menu adm content
require('./footer');                        // foooter component

//scroll bg header
var $document = $(document),
    $element = $('header'),
    className = 'bg-fixed',
    isDispAdv = _spPageContextInfo.serverRequestPath.toLowerCase().match(/detalhes-advogado/i);

//scroll fixed header
$document.scroll(function () {
    if ($document.scrollTop() >= 80) {
        // user scrolled 50 pixels or more;
        // do stuff
        if (isDispAdv) {
            $element.css(`display`, `block`);
        }
        $element.addClass(className);
    } else {
        if (isDispAdv) {
            $element.css(`display`, `none`);
        }
        $element.removeClass(className);
    }
});

$('.button-scroll[href*="#"]').on('click', function (e) {
    e.preventDefault()

    $('body, html').animate({
        scrollTop: $($(this).attr('href')).offset().top - 100,
    }, 500, 'linear')
})

$(document).ready(function () {
    if (_spPageContextInfo.serverRequestPath.match(/default.aspx/i)) {
        $('#ms-designer-ribbon').hide();
    }

    //get current year for display on footer
    var date = new Date();
    var year = date.getFullYear();
    $('.current-year').text(year);
})

//pegando o idioma e modificando exibição
if (CurrentLanguageLCID == 'pt-BR') {
    $('[lang="pt-br"]').show()
    $('[lang="en-us"]').hide()
} else {
    $('[lang="pt-br"]').hide()
    $('[lang="en-us"]').show();
}

//removendo menu fixo quando logado
if (_spPageContextInfo.userId != undefined) {
    var element = 'header'
    $(element).css({
        zIndex: '51',
        position: 'relative'
    });

    $('#ms-designer-ribbon').css({
        zIndex: '60',
        position: 'relative'
    })

    $('.custom-breadcrumb').css('zIndex', '50');
}