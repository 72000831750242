const formFields = require('./form-fields');
const spFormMixin = require('../mixins/sp-mixin');
const formMixin = require('../mixins/form-mixin');
require('../polyfills/polyfill.min.js');
import swal from 'sweetalert';

Vue.component(`form-newsletter`, {
    components: { formFields},
    mixins: [spFormMixin, formMixin, translateMixin],
    props: {
        ebook: { default: false }
    },
    data() {
        return {
            listUrl: _spPageContextInfo.webServerRelativeUrl + 'lists/Newsletter/',
            listName: 'Newsletter',
            fields: null,
            moment: moment,
            disabledButton: false,
            enabledButton: false,
            termosNews: false,
            checkboxAllow: false,
            locationNow: window.location.origin,
        }
    },
    template:`
    <element>
        <h3 class="text-primary">{{ebook? 'Ebook coletânea minerário' : 'Newsletter'}}</h3>
        <p class="text-secondary" v-if="ebook">{{ "Cadastre-se para acessar a coletânea do minerário, receber nossos conteúdos e convites para eventos." }}</p>
        <p class="text-white" v-if="!ebook">{{ isEnglish ? 'Sign up to receive our content and invitations to events.' : 'Cadastre-se para receber nossos conteúdos e convites para eventos.' }}</p>

        <div class="row">
            <div class="col-sm-12 form-group">
                <div class="form-newsletter" v-if="fields">             
                    <div class="form-row">
                        <div class="col">
                            <form-field :field="fields.Title" :tab-index="ebook ? 18 : 10" />
                        </div>
                        <div class="col">
                            <form-field :field="fields.Sobrenome" :tab-index="ebook ? 19 : 11" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <form-field :field="fields.Email" :tab-index="ebook ? 20 : 12" />
                        </div>
                    </div>
                    <div class="form-row" v-if="ebook">
                        <div class="col">
                            <form-field :field="fields.Cargo" :tab-index="ebook ? 21 : 13" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <form-field :field="fields.Empresa" :tab-index="ebook ? 22 : 14" />
                        </div>
                        <div class="col">
                            <form-field :field="fields.Pais" :tab-index="ebook ? 23 : 15" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <form-field :field="fields.Estado" :tab-index="ebook ? 24 : 16" />
                        </div>
                        <div class="col">
                            <form-field :field="fields.Cidade" :tab-index="ebook ? 25 : 17" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="termosNews" v-model="termosNews" />
                                <label class="form-check-label" for="termosNews">
                                    <template v-if="isEnglish">
                                        I declare that I have read and accept the <a href="/termos-de-uso" title="Terms of Use" target="_blank">Terms of Use</a> and 
                                        <a href="/politica-de-privacidade" title="Privacy Policy" target="_blank">Privacy Policy</a>
                                    </template>
                                    <template v-else>
                                        Declaro que li e aceito os <a href="/termos-de-uso" title="Termos de Uso" target="_blank">Termos de Uso</a> e a 
                                            <a href="/politica-de-privacidade" title="Política de Privacidade" target="_blank">Política de Privacidade</a>
                                    </template>
                                </label>
                            </div>
                            <div class="form-group form-check" v-if="ebook">
                                <input type="checkbox" class="form-check-input" id="checkboxComunicacoes" v-model="checkboxAllow" />
                                <label class="form-check-label" for="checkboxComunicacoes">
                                    <template v-if="isEnglish">
                                        Autorizo a inclusão na base para recebimento de comunicações do escritório
                                    </template>
                                    <template v-else>
                                        Autorizo a inclusão na base para recebimento de comunicações do escritório
                                    </template>
                                </label>
                            </div>
                            
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <button class="btn-enviar btn-primary text-white" type="button" @click="send" :tab-index="6" :disabled="!termosNews">{{ isEnglish ? 'Register' : 'Cadastre-se' }}</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </element>
    `,
    methods: {
        getFields() {
            return $pnp.sp.web.getList(this.listUrl).fields
                .filter(`CanBeDeleted eq true or InternalName eq 'Title'`)
                .get()
        },
        goToLocation(){
            return window.location.href = `${this.locationNow}/cesconbarrieuinsights/ebook-coletanea-minerario`
        },  
        applyFields(fields) {
            this.fields = fields.reduce((fieldObj, field) => {
                if(this.isEnglish){
                    if(field.InternalName == 'Title'){
                        field.Title = field.Description = 'First Name'
                    } 
                    
                    if(field.InternalName == 'Sobrenome'){
                        field.Title = field.Description = 'Last name'
                    }
                        
                    if(field.InternalName == 'Telefone'){
                        field.Title = field.Description = 'Phone'
                    }

                    if(field.InternalName == 'Mensagem'){
                        field.Title = field.Description = 'Message'
                    }

                    if(field.InternalName == 'Empresa'){
                        field.Title = field.Description = 'Office'
                    }

                    if(field.InternalName == 'Pais'){
                        field.Title = field.Description = 'Country'
                    }

                    if(field.InternalName == 'Estado'){
                        field.Title = field.Description = 'State'
                    }
                    if(field.InternalName == 'Cidade'){
                        field.Title = field.Description = 'City'
                    }
                }

                if(field.InternalName == 'Cargo' && this.ebook){
                    field.Title = field.Description = 'Cargo'
                    field.Required = true
                }

                field.Placeholder = field.Description || field.Title;
                field.HideLabel = true
                field.Value = null;
                field.Invalid = false;

                fieldObj[field.InternalName] = field;          
                return fieldObj;
            }, {});
            return fields;
        },
        loadFormType() {
            return Promise.resolve()
            
        },
        send() {
            this.showLoading();
            let invalidFields = this.getInvalidFields(this.fields);
            if(!this.termosNews) {
                return swal({
                    title: "Ops!",
                    text: `${this.isEnglish ? 'Please accept terms and conditions.' : 'Por favor, aceite os termos e condições.'}`,
                    icon: "error"
                });
            }
            if (invalidFields.fields.length) {
                this.invalidateFields(invalidFields.fields);
                console.log(invalidFields);
                return swal({
                    title: "Ops!",
                    text: `${this.isEnglish ? 'Please fill in the required fields.' : 'Por favor, preencha os campos obrigatórios.'}`,
                    icon: "error"
                });
            } else {
                this.saveSolicitacao()
                    .then(this.afterSave)
                    .catch(this.threatError.bind(this, 'erro ao salvar solicitação'));
            }
        },
        saveSolicitacao(email) {
            return $pnp.sp.web.getList(this.listUrl).items.add(this.getDataToSave())
        },
        afterSave() {
            swal({
                title: `${this.isEnglish ? 'Thank you!' : 'Obrigado!'}`,
                text: `${this.isEnglish ? 'Your registration was successful.' : 'Seu cadastro foi realizado com sucesso.'}`,
                type: "success",
            }).then(() => {
                this.clearForm();
                if(this.ebook) {
                    this.goToLocation();
                };
            })

        },
        clearForm() {
            Object.keys(this.fields).map(fieldName => {
                let field = this.fields[fieldName];

                Vue.set(field, 'Value', null);
                this.termosNews = false;
            })
        },
        
        invalidateFields(invalidFields) {
            invalidFields.forEach(fieldName => {
                Vue.set(this.fields[fieldName], 'Invalid', true);
            });
        },
        showLoading(title, msg) {
            title = title || `${this.isEnglish ? 'Wait...' : 'Aguarde...'}`;
            msg = msg || `${this.isEnglish ? 'Please wait a moment' : 'Por favor aguarde alguns instantes'}`;
            return swal({
                title,
                html: msg,
                allowOutsideClick: false,
                showConfirmButton: false
            });
        },
        closeLoading() {
            return swal.close();
        },
        threatError(msg, data) {
            console.error('error - ' + msg + ' - ' + data);
            swal({
                title: 'Ops!',
                text: 'Algo deu errado. Tente novamente',
                type: "error",
            });
        },
    },
    created() {
        this.loadFormType()
            .then(this.getFields)
            .then(this.applyFields.bind(this), this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
    }
});