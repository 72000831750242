$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component('menu-adm-content', {
    template: `
        <div class="ux-edit" v-if="isGroupGestor" v-show="contentAdmin.length" :class="userIsClosed ? 'minimized' : 'opened'">
            <h3 class="text-white text-uppercase">Opções de Gerenciamento</h3>
            <div class="row">
                <div class="col-sm-11">
                    <ul class="nav">
                        <li class="nav-item" v-for="link in contentAdmin">
                            <a class="nav-link bg-light" :href="link.URL" :title="link.Title">{{ link.Title }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-1 text-center button">
                    <a href="javascript:" @click="toggleMenu($event.target)" title="Ocultar" class="text-white font-weight-bold">
                        <span class="icon" v-if="userIsClosed">+</span>
                        <span class="icon" v-else>-</span>
                    </a>
                </div>
            </div>
        </div>
    `,
    data: function() {
        var listName = 'MenuAdminContent'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.siteServerRelativeUrl + '/lists/' + listName + '',
            contentAdmin: [],
            isGroupGestor: null,
            userIsClosed: false,
            localStorageMenu: window.localStorage.getItem('menuAdminClosed'),
            groupUserLogado: []
        }
    },
    created: function() {
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this, 'erro ao obter informações da lista ' + this.listName + ''))
            .then(this.checkGroup)
            .then(this.verifyVisibleMenu)
    },
    methods: {
        verifyVisibleMenu(){
            if(this.localStorageMenu == 'open'){
                this.userIsClosed = false
            }else {
                this.userIsClosed = true;
            }
        },
        getData: function() {
            var pageID = _spPageContextInfo.pageItemId;
            var siteUrl = ''
            if(_spPageContextInfo.siteServerRelativeUrl.match(/pi/i)){
                siteUrl
            }
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                .filter(`AuxPaginas/Id eq ${pageID}`)
                .select('*,AuxPaginas/Id')
                .expand(`AuxPaginas`)
                .orderBy('Ordem', true)
                //.top()
                .get()
        },
        applyGetData: function(data) {
            this.contentAdmin = data;
        },
        threatError: function(msg, data) {
            console.error('error - ' + msg + ' - ' + data);
        },
        checkGroup: function() {
            if (!_spPageContextInfo.userId) return Promise.resolve();
            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.siteUsers.getById(_spPageContextInfo.userId).expand('groups')
                .get()
                .then(userGroups => {
                    this.$set(this, 'groupUserLogado', userGroups.Groups.results)
                    return userGroups.Groups.results.find(grupo => {
                        if (grupo.Title === 'Cescon Owners') {
                            this.isGroupGestor = true
                        }
                    })
                })
        },
        toggleMenu(el){
            if(this.userIsClosed == false){
                this.userIsClosed = true;
                window.localStorage.setItem('menuAdminClosed', 'close');
                $(el).parent().addClass('close');
            }else{
                this.userIsClosed = false;
                window.localStorage.setItem('menuAdminClosed', 'open');
                $(el).parent().removeClass('close');
            }
        }
    }
})

var app = new Vue({
    el: '#menu-adm-content',
    template: '<menu-adm-content/>'
})