$pnp.setup({
    sp: {
        headers: {
            "Accept": "application/json; odata=verbose"
        }
    }
});

Vue.component('redes-sociais',{
    template:`
        <element>
            <ul class="nav socials-links">
                <li v-for="item in redesSociais" class="nav-item">
                    <a :href="item.URL" :title="item.Title" target="_blank" class="nav-link">
                        <i :class="'fab ' + item.Icone"></i>
                    </a>
                </li>
            </ul>
        </element>
    `,
    created: function(){
        this.getData()
            .then(this.applyGetData, this.threatError.bind(this,'erro ao obter informações da lista '+this.listName+''))
            .then(this.applyEvents)
    },
    methods: {
        getData: function(){
            return $pnp.sp.web.getList(this.listUrl).items
                //.filter()
                .select('*')
                //.expand()
                .orderBy('Ordem',true)
                //.top()
                .get()
        },
        applyGetData: function(data){
            this.redesSociais = data;
        },
        applyEvents: function(){

        },
        threatError: function(msg,data){
            console.error('error - ' + msg + ' - ' + data);
        }
    },
    data: function() {
        var listName = 'RedesSociais'
        return {
            listName: listName,
            listUrl: _spPageContextInfo.webServerRelativeUrl + '/lists/'+ listName+'',
            redesSociais: []
        }
    }
})