require('./menu-idiomas');
require('../mixins/translate');
require('./menu-areas-atuacao');
function menuGlobalScope(Vue, $pnp, pnpMixin){
	var menuAdmMixin = {
		data: function(){
			return {
				isMobile: false
			}
		},
		created: function(){
			this.onResize();
			$(window).resize(this.debounce(this.onResize.bind(this),500))
		},
		methods: {
			closeOpenedItems: function(items){
				items.forEach(function(item){
					if(item.isOpened){
						item.isOpened = false;
						if(item.children)
							this.closeOpenedItems(item.children);
					}
				}.bind(this));
			},
			onResize: function(){
				this.isMobile = window.innerWidth < 1024
			},
			debounce: function(func, wait, immediate) {
				var timeout;
				return function() {
					var context = this, args = arguments;
					var later = function() {
						timeout = null;
						if (!immediate) func.apply(context, args);
					};
					var callNow = immediate && !timeout;
					clearTimeout(timeout);
					timeout = setTimeout(later, wait);
					if (callNow) func.apply(context, args);
				};
			}
		}
	};
	
	$pnp.setup({
        headers: {
            "Accept": "application/json; odata=verbose"
        }
    });
    
    Vue.component('add-item-menu',{
    	props: ['item'],
    	template:`
        <a href="javascript:" title="add item">
            <i class="fa fa-plus"></i>
            <template v-if="item.ItemPrincipalId"> add item</template>
        </a>`
    })

	Vue.component('menu-global-multinivel', {
		mixins: [menuAdmMixin, translateMixin],
		props:{
			items: { 
				type: Array, 
				default: function(){ 
					return []
				} 
			},
			customClass: {
				type: String,
				default: ''
			},
			isChild: {
				type: Boolean,
				default: false
			}
		},
		data: function() {
			return {
				list: {
					newUrl: _spPageContextInfo.webAbsoluteUrl + '/lists/MenuGlobal/new.aspx',
				},
				openMegaMenu: false
			}
		},
		methods: {
			onItemMouseHover: function(item){
				if(item.children.length){
					if(!this.isMobile){
						this.closeOpenedItems(this.items);
					}
					Vue.set(item, 'isOpened', !item.isOpened);
				}
			},
			hasChildren: function(item){
				return (item.children||[]).length > 0;
			},
			isActive(item) {
				var childrenIsActive = false;

				var checkChildren = item.children.filter((children) => {
					if (_spPageContextInfo.serverRequestPath.indexOf(children.URL) > -1) {
						childrenIsActive = true;
					}
				})

				if (checkChildren.length)
					childrenIsActive = true;

				if((_spPageContextInfo.serverRequestPath.indexOf(item.URL) >-1 || (item.children.length && childrenIsActive)) && item.Title != 'Home'){
					return true
				}else if(item.Title == 'Home' && _spPageContextInfo.serverRequestPath.toLowerCase().indexOf('default.aspx') >-1){
					return true
				}
			},
			clickToOpen(item, el){
				if(item.Id == 1){
					return false
				}
				
				if(!this.isMobile){
					if(item.Id == 3){
						if(this.openMegaMenu == true){
							this.openMegaMenu = false;
						}else {
							el.classList.add('active');
							this.openMegaMenu = true;
						}
					}else {
						return window.location.href = item.URL;
					}
				}else {
					return window.location.href = item.URL;
				}
			},
		},
		template: 
		`
		<ul :class="isChild ? customClass : 'navbar-nav mr-auto'">
			<template v-for="(item, index) in items">
				<li :class="{'dropdown': hasChildren(item), 'dropdown-item' : isChild, 'active' : isActive(item)}" class="nav-item"
					@mouseleave.stop.prevent="item.isOpened = false" 
					@mouseover.stop.prevent="onItemMouseHover(item)" 
				>
					<template v-if="item.URL == '/areas-atuacao'">
						<a href="javascript:" class="nav-link"
							@click="clickToOpen(item, $event.target)"
							:class="{'dropdown-toggle': (item.children||[]).length}"
							:id="'item'+ (item.ItemPrincipalId || item.Id)" 
							:data-toggle="hasChildren(item) ? 'dropdown' : ''"
							:aria-haspopup="hasChildren(item) ? 'true' : ''"
							:aria-expanded="hasChildren(item) ? 'false' : ''"
							:target="item.NovaAba == 'Sim' ? '_blank' : '_self'"
						>
							{{ !isEnglish ? item.Title : item.TitleEN }}
						</a>
					</template>
					<template v-else>
						<a :href="item.URL" class="nav-link"
							:class="{'dropdown-toggle': (item.children||[]).length}"
							:id="'item'+ (item.ItemPrincipalId || item.Id)" 
							:data-toggle="hasChildren(item) ? 'dropdown' : ''"
							:aria-haspopup="hasChildren(item) ? 'true' : ''"
							:aria-expanded="hasChildren(item) ? 'false' : ''"
							:target="item.NovaAba == 'Sim' ? '_blank' : '_self'"
						>
							{{ !isEnglish ? item.Title : item.TitleEN }}
						</a>
					</template>
					<menu-global-multinivel v-if="hasChildren(item)" v-show="item.isOpened" style="display: block" :items="item.children" custom-class="dropdown-menu" :is-child="true" :id="'item'+ item.Id"/>
					<menu-areas-atuacao v-show="openMegaMenu" v-if="item.Id == 3"></menu-areas-atuacao>
				</li>
			</template>
			
		</ul>`,
		created() {
			window.addEventListener('click', function(e){   
				if(this.openMegaMenu){
					if (document.getElementById('mega-menu-areas').contains(e.target) || document.getElementById('item3').contains(e.target)){
						// Clicked in box
						this.openMegaMenu = true;
					} else{
						this.openMegaMenu = false
					}
				}
			}.bind(this));
		},
	})

	Vue.component('menu-component', {
		mixins: [translateMixin, pnpMixin, menuAdmMixin],
		data: function(){
			return {
				items: [],
				isOpened: false,
				isUserAllowed: false,
				searchVisible: false
			}
		},
		created: function(){
			this.getItems(_spPageContextInfo.siteServerRelativeUrl, _spPageContextInfo.siteAbsoluteUrl)
				.then(this.parseLevels)
				.then(this.setItems)
		},
		mounted: function(){
			document.onclick = this.onClickOutside;
		},
		methods: {
			onClickOutside: function(){
				this.closeOpenedItems(this.items);
				this.openMenu(false)
				this.openMegaMenu = false
			},
			getItems: function(webRelative, webAbsolute){
				var web = new $pnp.Web(webAbsolute);
			
				return web.getList(webRelative + '/lists/MenuGlobal').items
					.top(500)
					.select(`*, Idioma/LCID`)
					.orderBy('Ordem')
					.expand(`Idioma`)
					.filter(`TipoMenu eq 'Principal' and Idioma/LCID eq '${CurrentLanguageLCID}'`)
					.get()
			},
			setItems: function(items){
				this.items = items;
			},
			getChildren: function(items, parentId){
				return items.filter(function(item){
					return parentId === item.ItemPrincipalId;
				}).map(function(item){
					item.levelId = item.ItemPrincipalId;
					return item;
				})
			},
			clone: function(obj){
			    if (null == obj || "object" != typeof obj) return obj;
			    var copy = obj.constructor();
			    for (var attr in obj) {
			        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
			    }
			    return copy;
			},
			cloneArray: function(array){
				return JSON.parse(JSON.stringify(array));
			},
			parseLevels: function(items){	
				var allItems = this.cloneArray(items);
				var reduceLevels = function(levelAtual, curItem, index){
					// é necessário clonar pois propriedades como levelId e children são alteradas nos loops
					// quando não clonamos, corremos o risco de perder a referencia de levelId por exemplo

					if(curItem.levelId == curItem.ItemPrincipalId || curItem.ID == curItem.levelId){
						curItem.levelId = curItem.ID;
						curItem.children = this.getChildren(allItems, curItem.ID).reduce(reduceLevels.bind(this), []);
						levelAtual.push(curItem)
					}
					return levelAtual;
				}
				
				items = items.reduce(reduceLevels.bind(this), []); 	
				return items;
			},
			onMenuClick: function(){
				this.openMenu(!this.isOpened);
			},
			openMenu: function(open){
				this.isOpened = open;
			},
			openSearch(el){
				if(this.searchVisible){
					this.searchVisible = false
				}else {
					this.searchVisible = true;
				}
			}
		},
        template: `
        <div class="Menu">
            <nav class="navbar navbar-expand-lg navbar-light" v-if="items.length">
				<a class="nav-brand mr-5" href="/" :title="_spPageContextInfo.webTitle">
					<img :src="_spPageContextInfo.webLogoUrl" :alt="_spPageContextInfo.webTitle"/>
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
				</button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
					<menu-global-multinivel :items="items" :is-mobile="isMobile"/>
					<menu-idiomas></menu-idiomas>
					<redes-sociais class="justify-content-end"></redes-sociais>
					
                </div>
            </nav>
        </div>`
	});
	
	new Vue({
		el: '#menu-component',
		template: '<menu-component/>'
	})
}
menuGlobalScope(Vue, $pnp, pnpMixin);