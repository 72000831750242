$pnp.setup({
    sp: {
        headers: {
            "Accept": "application/json; odata=verbose"
        }
    }
});
require('../mixins/translate');
Vue.component('menu-idiomas',{
    mixins: [translateMixin],
    template:`
        <element class="idiomas">
            <div class="translate text-sm-right d-sm-none d-md-block justify-content-ends mr-3">
                <a v-for="item in idiomas" href="javascript:" @click="changeLanguage(item.LCID)" :class="currentLang == item.LCID ? 'text-piscina' : ''" :title="isEnglish ? item.AltEN : item.Alt">
                    <img :src="item.Flag" :alt="isEnglish ? item.AltEN : item.Alt" />
                </a>
            </div>
        </element>
    `,
    data: function() {
        return {
            idiomas: [
                {
                    Alt:        'Português',
                    AltEN:      'Portuguese',
                    Title:      'PT',
                    LCID:       '1046',
                    Flag:       `${_spPageContextInfo.siteAbsoluteUrl}/style library/images/flag-br.png`
                },
                {
                    Alt:        'Inglês',
                    AltEN:      'English',
                    Title:      'EN',
                    LCID:       '1033',
                    Flag:       `${_spPageContextInfo.siteAbsoluteUrl}/style library/images/flag-eua.png`
                }
            ]
        }
    }
})