$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

require(`../mixins/translate`);

Vue.component(`cookies`, {
    mixins: [translateMixin],
    data() {
        return {
            moment: moment,
            dataExpiracao: ``,
        }
    },
    template: `
    <element id="modalCookiesSection">
        <div class="overlay">
            <div class="modal-cookies" id="modalCookies">
                <h2 class="text-white font-weight-bold h4">
                    <span>Cookies</span>
                </h2>
                <div class="txt-modal mb-3">
                    <p v-if="!isEnglish">Utilizamos ferramentas e serviços de terceiros que utilizam cookies. 
                    Essas ferramentas nos ajudam a oferecer uma melhor experiência de navegação no site. 
                    Ao clicar no botão 'Aceito', você concorda com o uso de cookies em nosso site.</p>
                    <p v-else>
                    We use third-party tools and services that use cookies.
                     These tools help us to provide you with a better browsing experience on the site.
                     By clicking on the 'Accept' button, you agree to the use of cookies on our website.
                    </p>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <button id="cookieAcceptBarConfirm" type="button" class="btn bg-white rounded mr-1 p-2">{{ isEnglish ? 'Accept' : 'Aceito' }}</button>
                    </div>
                    <div class="col-sm-9">
                        <a class="w-100 d-block" href="javascript:" @click="GetBrowserInfo" target="_blank" :title="isEnglish ? 'View cookie settings' : 'Ver definições de cookies'">
                            {{ isEnglish ? 'View cookie settings' : 'Ver definições de cookies' }}
                        </a>
                        <a class="w-100 d-block" :href="_spPageContextInfo.siteAbsoluteUrl + '/pages/politica-de-privacidade.aspx'" :title="isEnglish ? 'Privacy Policy' : 'Política de Privacidade'">{{ isEnglish ? 'Privacy Policy' : 'Política de Privacidade'}}</a>
                    </div>
                </div>
            </div>
        </div>
    </element>
    `,
    methods: {
        GetBrowserInfo() {
            var isFirefox = typeof InstallTrigger !== 'undefined';   // Firefox 1.0+
            var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            var isChrome = !!window.chrome;              // Chrome 1+
            var isIE = /*@cc_on!@*/false || !!document.documentMode;   // At least IE6
            var isSafariCel = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if (isFirefox) {
                return window.open('https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador');
            }
            else if (isChrome) {
                return window.open('https://support.google.com/chrome/answer/95647?hl=pt');
            }
            else if (isSafariCel) {
                return window.open('https://support.apple.com/pt-br/HT201265');
            }
            else if (isIE) {
                return window.open('https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies');
            }
            else if (isSafari) {
                return ('https://support.apple.com/pt-br/guide/safari/sfri11471/mac');
            }
        }
    },
    created() {
        $(document).ready(function () {
            if ($.cookie('CesconCookie') != "active") {
                $('.overlay').show();
                $('body').addClass('overflow');
            }
            //Assign cookie on click
            $('#cookieAcceptBarConfirm').on('click', function () {
                $.cookie('CesconCookie', 'active', { expires: 1 }); // cookie will expire in one day
                $('#cookieAcceptBar').fadeOut();
                $('#modalCookiesSection').hide();
                $('body').removeClass('overflow');
            });
        });
    }
})